import React from "react";
import { Autocomplete, TextField, Box, Typography, CircularProgress } from "@mui/material";

const StepLocation = ({ userInput, data, onDataChange }) => { // 🔹 Ändrat från onLocationChange till onDataChange
  const { countryIds } = userInput.location || {}; // 🔹 Se till att userInput.location alltid har ett värde
  let countries = Array.isArray(data?.countries) ? data.countries : [];

  if (!data.countries || data.countries.length === 0) {
    return (
      <Box textAlign="center">
        <CircularProgress />
        <Typography>Laddar länder...</Typography>
      </Box>
    );
  }

  // Sortera länder alfabetiskt men ha Sverige högst upp
  countries = countries.sort((a, b) => {
    if (a.name === "Sverige") return -1;
    if (b.name === "Sverige") return 1;
    return a.name.localeCompare(b.name);
  });

  const handleCountryChange = (event, values) => {
    if (typeof onDataChange === "function") {  // 🔹 Använd onDataChange istället
      onDataChange("location", { countryIds: values.map((v) => v.id) });
    } else {
      console.error("⚠️ onDataChange är inte en funktion.");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Typography variant="h6">Steg 1: Välj plats</Typography>
      <Autocomplete
        multiple
        options={countries}
        getOptionLabel={(option) => option.name || "Okänt land"}
        value={countries.filter((country) => countryIds?.includes(country.id))}
        onChange={handleCountryChange}
        renderInput={(params) => (
          <TextField {...params} label="Välj land/länder" variant="outlined" />
        )}
      />
    </Box>
  );
};

export default StepLocation;
