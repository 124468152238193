
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif", // Enhetligt typsnitt
    h1: { fontSize: "2.5rem", fontWeight: 700 },
    h2: { fontSize: "2rem", fontWeight: 600 },
    h3: { fontSize: "1.75rem", fontWeight: 500 },
    h4: { fontSize: "1.5rem", fontWeight: 500 },
    body1: { fontSize: "1rem", color: "#333" },
    body2: { fontSize: "0.875rem", color: "#666" },
    button: { textTransform: "none", fontWeight: 600 },
  },
  palette: {
    primary: {
      main: "#007bff", // Blå primärfärg
    },
    secondary: {
      main: "#4CAF50", // Grön sekundärfärg
    },
    background: {
      default: "#f8f9fa", // Ljus bakgrund
      paper: "#ffffff",
    },
    text: {
      primary: "#212529",
      secondary: "#6c757d",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          padding: "10px 20px",
          fontSize: "1rem",
        },
      },
    },
  },
});

export default theme;
