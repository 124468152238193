import React, { useState, useEffect } from "react";
import Hero from "../components/Hero.js"; // Lägg till .js
import { Box, Typography, Grid, Card, CardContent, Avatar } from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircle";

const HomePage = () => {
  const [isAboutVisible, setIsAboutVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.getElementById("about-section");
      if (!aboutSection) return;

      const rect = aboutSection.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight / 2 && rect.bottom > window.innerHeight / 2;
      
      setIsAboutVisible(isVisible);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box sx={{ paddingTop: "80px", marginTop: "80px" }}>
      
      {/* Hero-sektion framhävd */}
      <Box
        sx={{
          background: "linear-gradient(180deg, #FFFFFF,rgb(245, 245, 245))", // Mjuk gradient
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)", // Lätt skugga
          padding: "50px 20px",
        }}
      >
        <Hero />
      </Box>

      {/* Bullet-sektion */}
      <Box sx={{ textAlign: "center", py: 8 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
          Svenska stipendier samlade på ett ställe
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {[
            { title: "Se alla stipendier du kan söka", description: "Fyll i dina kriterier och få en lista över stipendier du är behörig för, med länkar direkt till ansökan hos givaren." },
            { title: "Fler stipendier än du tror", description: "Många stipendier blir aldrig sökta. Missa inte möjligheter som passar dig." },
            { title: "Gratis", description: "Ingen betalvägg, inga dolda avgifter. Hitta stipendier utan krångel." },
          ].map((bullet, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card 
                sx={{ 
                  borderRadius: "12px", 
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
                  display: "flex", 
                  flexDirection: "column", 
                  height: "100%" 
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: "center", display: "flex", flexDirection: "column" }}>
                  <Avatar sx={{ backgroundColor: "#4CAF50", width: 50, height: 50, mx: "auto", mb: 2 }}>
                    <CheckIcon sx={{ color: "white", fontSize: 30 }} />
                  </Avatar>

                  <Typography variant="h6" sx={{ fontWeight: "bold", color: "rgb(103, 131, 103)", mb: 1 }}>
                    {bullet.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#6C757D", flexGrow: 1 }}>
                    {bullet.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Om oss-sektion med kontrastfärg från MUI */}
      <Box
        id="about-section"
        sx={{
          backgroundColor: isAboutVisible ? "#F5F5F5" : "white", // Ljusgrå från MUI-paletten
          transition: "background-color 0.5s ease-in-out",
          p: 8,
          textAlign: "center",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
          Om oss
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: "600px", mx: "auto", color: "#444" }}>
        Sverige har tusentals stipendier för studier, forskning och andra project, men att hitta rätt kan vara både svårt och tidskrävande. Informationen är utspridd på olika hemsidor, i stiftelseregister och böcker, och det är ofta oklart vilka kriterier som gäller för varje stipendium. Stipendio förenklar processen genom att samla och organisera stipendier på ett ställe, så att du snabbt kan hitta de möjligheter som passar dig utan att behöver lägga tid på att leta. 
        <br/>Med en enkel sökning filtreras stipendier baserat på dina kriterier, så att du bara ser de du faktiskt kan söka. För varje stipendium får du information om ansökningskrav, sista ansökningsdatum och en direkt länk till stipendiegivarens ansökan. 
        
        <br/>Vi vill att Sveriges stipendier ska vara så lättillgängliga som möjligt för alla som behöver dem, utan kostnader och krångel. Genom att förenkla sökprocessen ser vi till att fler sökande hittar rätt samtidigt som stipendiegivare får fler kvalificerade ansökningar. 

Vårt register bygger på omfattande sökningar, befintliga stipendielistor från organisationer inom olika ämnesområden och en rad andra källor. Vi lägger successivt till information om stipendier inom nya områden. 
        </Typography>
      </Box>
    </Box>
  );
};

export default HomePage;
