import React from "react";
import { Box, Typography, ToggleButton, ToggleButtonGroup, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import * as PopoverPrimitive from "@radix-ui/react-popover";

const StepPurpose = ({ userInput, data, onDataChange }) => {
  const purpose = userInput.purpose || [];
  const purposes = Array.isArray(data?.purpose) ? data.purpose : [];

  const handlePurposeChange = (event, newSelection) => {
    onDataChange("purpose", newSelection);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, maxWidth: 600, margin: "0 auto" }}>
      <Typography variant="h6">Steg 2: Välj syfte</Typography>

      {purposes.length === 0 ? (
        <Typography variant="body1">Inga ändamål tillgängliga</Typography>
      ) : (
        <ToggleButtonGroup
          value={purpose}
          onChange={handlePurposeChange}
          orientation="vertical"
          aria-label="Välj ändamål"
          sx={{ width: "100%" }}
        >
          {purposes.map((p) => (
            <ToggleButton
              key={p.id}
              value={p.id}
              sx={{
                justifyContent: "space-between",
                borderRadius: "5px",
                fontSize: "16px",
                padding: "10px",
                textAlign: "left",
                "&.Mui-selected": { backgroundColor: "#007BFF", color: "white" },
              }}
            >
              {p.name}

              {p.description && (
                <PopoverPrimitive.Root>
                  <PopoverPrimitive.Trigger asChild>
                    <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </PopoverPrimitive.Trigger>
                  <PopoverPrimitive.Portal>
                    <PopoverPrimitive.Content
                      className="p-4 bg-white shadow-md rounded-md text-sm border border-gray-200 max-w-[300px] overflow-auto"
                      side="right"
                      align="center"
                      sideOffset={5}
                      collisionPadding={10}
                      avoidCollisions
                    >
                      <Typography variant="body2">{p.description}</Typography>
                    </PopoverPrimitive.Content>
                  </PopoverPrimitive.Portal>
                </PopoverPrimitive.Root>
              )}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </Box>
  );
};

export default StepPurpose;
