import React from "react";
import WizardContainer from "../components/wizard/WizardContainer.js"; // Lägg till .js
import { Box, Typography } from "@mui/material";

const FindScholarshipPage = ({ isTestEnvironment }) => {
  return (
    <Box sx={{ paddingTop: "80px", textAlign: "center" }}>
      <Typography variant="body1" sx={{ color: "#444", fontSize: "18px", marginBottom: "20px" }}>
        Fyll i dina uppgifter för att hitta stipendier som passar dig.
      </Typography>
      <WizardContainer isTestEnvironment={isTestEnvironment} /> {/* Skicka isTestEnvironment här */}
    </Box>
  );
};

export default FindScholarshipPage;