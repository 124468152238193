import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme.js";
import HomePage from "./pages/HomePage.js";
import FindScholarshipPage from "./pages/FindScholarshipPage.js";
import Layout from "./components/Layout.js";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/find-scholarships"
              element={<FindScholarshipPage isTestEnvironment={true} />}
            />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;