import { SunIcon, MoonIcon } from "@heroicons/react/24/solid";
import React, { useState, useEffect } from "react";

const DarkModeToggle = () => {
  const enableDarkMode = false; // Sätt till false för att inaktivera dark mode

  // Dölj knappen om dark mode är inaktiverat
  if (!enableDarkMode) return null;

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div
      onClick={() => setDarkMode(!darkMode)}
      className={`w-14 h-8 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${
        darkMode ? "bg-gray-800" : "bg-gray-200"
      }`}
    >
      <div
        className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 flex items-center justify-center ${
          darkMode ? "translate-x-6" : "translate-x-0"
        }`}
      >
        {darkMode ? (
          <MoonIcon className="h-4 w-4 text-gray-800" />
        ) : (
          <SunIcon className="h-4 w-4 text-yellow-500" />
        )}
      </div>
    </div>
  );
};

export default DarkModeToggle;