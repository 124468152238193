import React, { useState, useEffect, useMemo, useCallback } from "react";
import StepAge from "./StepAge.js";
import StepLocation from "./StepLocation.js";
import StepPurpose from "./StepPurpose.js";
import StepField from "./StepField.js";
import StepEducationLevel from "./StepEducationLevel.js";
import ResultList from "./ResultList.js";
import { Box, Button, LinearProgress, Typography, Fade, CircularProgress } from "@mui/material";
import { createClient } from "@supabase/supabase-js";

// Supabase setup
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const WizardContainer = ({ isTestEnvironment }) => {
  const steps = [
    { id: 0, label: "Ange din ålder", component: StepAge },
    { id: 1, label: "Välj plats", component: StepLocation },
    { id: 2, label: "Välj syfte", component: StepPurpose },
    { id: 3, label: "Välj ämnesområde", component: StepField },
    { id: 4, label: "Välj utbildningsnivå", component: StepEducationLevel },
    { id: 5, label: "Dina resultat", component: ResultList },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [userInput, setUserInput] = useState({
    age: "",
    isCitizen: true,
    location: { countryIds: [] },
    purpose: [],
    field: [],
    educationLevel: null,
  });

  const [data, setData] = useState({
    countries: [],
    purpose: [],
    field: [],
    educationLevels: [],
  });

  const [loading, setLoading] = useState(true);
  const [scholarships, setScholarships] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: cachedData, error } = await supabase
          .from("cached_scholarships")
          .select("cached_data")
          .eq("id", 11)
          .single();

        if (error || !cachedData || !cachedData.cached_data) {
          throw new Error("Inga data i cache.");
        }

        const { scholarships } = cachedData.cached_data;

        setData({
          countries: cachedData.cached_data.countries || [],
          purpose: cachedData.cached_data.purpose || [],
          field: cachedData.cached_data.field || [],
          educationLevels: cachedData.cached_data.education_level || [],
        });

        setScholarships(scholarships || []);
      } catch (err) {
        console.error("❌ Fel vid hämtning av cachedData:", err);
        setData({ countries: [], purpose: [], field: [], educationLevels: [] });
        setScholarships([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getEducationWeight = useCallback((educationLevelId) => {
    const level = data.educationLevels.find((lvl) => lvl.id === educationLevelId);
    return level ? level.education_weight : 0;
  }, [data.educationLevels]);

  const filterScholarships = useCallback(() => {
    if (!Array.isArray(scholarships)) return [];

    return scholarships.filter((scholarship) => {
      if (!scholarship) return false;

      // 🔹 1. Age filter
      if (
        userInput.age &&
        (scholarship.min_age == null || scholarship.max_age == null)
      ) {
        return false;
      }

      if (
        userInput.age &&
        (userInput.age < scholarship.min_age || userInput.age > scholarship.max_age)
      ) {
        return false;
      }

      // 🔹 2. Education level filter
      if (userInput.educationLevel) {
        const userEducationWeight = getEducationWeight(userInput.educationLevel);
        const hasValidEducationLevel =
          scholarship.education_levels &&
          Array.isArray(scholarship.education_levels) &&
          scholarship.education_levels.some((levelId) => {
            const scholarshipWeight = getEducationWeight(levelId);
            return scholarshipWeight <= userEducationWeight;
          });

        if (!hasValidEducationLevel) {
          return false;
        }
      }

      // 🔹 3. Field filter
      if (
        userInput.field.length > 0 &&
        (!scholarship.fields ||
          !Array.isArray(scholarship.fields) ||
          scholarship.fields.filter((f) => userInput.field.includes(f)).length === 0)
      ) {
        return false;
      }

      // 🔹 4. Location filter
      if (
        userInput.location.countryIds.length > 0 &&
        (!scholarship.countries ||
          !Array.isArray(scholarship.countries) ||
          scholarship.countries.filter((c) => userInput.location.countryIds.includes(c)).length === 0)
      ) {
        return false;
      }

      // 🔹 5. Purpose filter
      if (
        userInput.purpose.length > 0 &&
        (!scholarship.purpose ||
          !Array.isArray(scholarship.purpose) ||
          scholarship.purpose.filter((p) => userInput.purpose.includes(p)).length === 0)
      ) {
        return false;
      }

      // 🔹 6. Citizenship filter
      if (scholarship.swe_citizenship_required && !userInput.isCitizen) {
        return false;
      }

      return true;
    });
  }, [userInput, scholarships, data.educationLevels, getEducationWeight]);

  const filteredScholarships = useMemo(() => filterScholarships(), [filterScholarships]);

  const handleDataChange = useCallback((key, value) => {
    setUserInput((prev) => ({ ...prev, [key]: value }));
  }, []);

  const handleAutoFill = useCallback(() => {
    const newState = {
      age: 25,
      isCitizen: true,
      location: { countryIds: [6, 98] },
      purpose: [6],
      field: [3, 4],
      educationLevel: 5,
    };

    setUserInput(newState);

    setTimeout(() => {
      setCurrentStep(5);
    }, 300);
  }, []);

  const validateStep = useCallback(() => {
    switch (currentStep) {
      case 0:
        return !!userInput.age;
      case 1:
        return userInput.location.countryIds.length > 0;
      case 2:
        return userInput.purpose.length > 0;
      case 3:
        return userInput.field.length > 0;
      case 4:
        return !!userInput.educationLevel;
      default:
        return true;
    }
  }, [currentStep, userInput]);

  const renderStep = useCallback(() => {
    if (currentStep === 5) {
      return (
        <ResultList
          scholarships={filteredScholarships}
          cachedData={data}
          userInput={userInput}
          setUserInput={setUserInput}
          setCurrentStep={setCurrentStep}
          isTestEnvironment={isTestEnvironment}
        />
      );
    }

    const StepComponent = steps[currentStep]?.component;
    return StepComponent ? (
      <StepComponent
        userInput={userInput}
        onDataChange={handleDataChange}
        data={data}
        scholarships={filteredScholarships}
        setCurrentStep={setCurrentStep}
      />
    ) : null;
  }, [currentStep, filteredScholarships, data, userInput, isTestEnvironment, handleDataChange]);

  return (
    <Box sx={{ width: "100%", maxWidth: "600px", margin: "auto", textAlign: "center", padding: 4 }}>
      <Typography variant="h5" gutterBottom>
        {steps[currentStep]?.label}
      </Typography>

      <LinearProgress
        variant="determinate"
        value={currentStep === steps.length - 1 ? 100 : (currentStep / (steps.length - 1)) * 100}
        sx={{ marginBottom: 2 }}
      />

      <Fade in key={currentStep} timeout={500}>
        <Box>{loading ? <CircularProgress /> : renderStep()}</Box>
      </Fade>

      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 4 }}>
        {currentStep > 0 && (
          <Button variant="outlined" onClick={() => setCurrentStep((prev) => prev - 1)}>
            Föregående
          </Button>
        )}

        {currentStep < steps.length - 1 && (
          <Button
            variant="contained"
            onClick={() => {
              if (validateStep()) {
                setCurrentStep((prev) => prev + 1);
              } else {
                alert("Vänligen fyll i alla obligatoriska fält innan du går vidare.");
              }
            }}
          >
            Nästa
          </Button>
        )}
      </Box>

      {currentStep === 0 && (
        <Button variant="contained" color="secondary" onClick={handleAutoFill} sx={{ marginTop: 2 }}>
          Fyll i automatiskt och visa resultat
        </Button>
      )}

      {currentStep < 5 && (
        <Button
          variant="outlined"
          onClick={() => {
            setUserInput({
              age: "",
              isCitizen: true,
              location: { countryIds: [] },
              purpose: [],
              field: [],
              educationLevel: null,
            });
            setCurrentStep(0);
          }}
          sx={{ marginTop: 2 }}
        >
          Återställ formulär
        </Button>
      )}
    </Box>
  );
};

export default WizardContainer;