import React from "react";
import { Box, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";

const StepField = ({ userInput, data, onDataChange }) => {
  const field = userInput.field || [];
  const fields = Array.isArray(data?.field) ? data.field : [];

  const handleFieldChange = (event, newSelection) => {
    onDataChange("field", newSelection);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, maxWidth: 600, margin: "0 auto" }}>
      <Typography variant="h6">Steg 3: Välj ämnesområde</Typography>

      {fields.length === 0 ? (
        <Typography variant="body1">Inga ämnesområden tillgängliga</Typography>
      ) : (
        <ToggleButtonGroup
          value={field}
          onChange={handleFieldChange}
          orientation="vertical" // ✅ Gör listan vertikal
          aria-label="Välj ämnesområde"
          sx={{ width: "100%" }}
        >
          {fields.map((f) => (
            <ToggleButton 
              key={f.id} 
              value={f.id} 
              sx={{ 
                justifyContent: "flex-start", 
                borderRadius: "5px", 
                fontSize: "16px", 
                padding: "10px",
                textAlign: "left", // ✅ Säkerställer att texten är vänsterjusterad
                "&.Mui-selected": { backgroundColor: "#007BFF", color: "white" }
              }}
            >
              {f.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </Box>
  );
};

export default StepField;