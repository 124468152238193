import React, { useMemo } from "react";
import { Box, Typography, ToggleButton, ToggleButtonGroup, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import * as PopoverPrimitive from "@radix-ui/react-popover";

const StepEducationLevel = ({ userInput, data, onDataChange }) => {
  const educationLevel = userInput.educationLevel || null;
  const educationLevels = Array.isArray(data?.educationLevels) ? data.educationLevels : [];

  // Hämta vikt för vald nivå
  const selectedWeight = useMemo(() => {
    if (!educationLevel) return null;
    const selected = educationLevels.find((lvl) => lvl.id.toString() === educationLevel.toString());
    return selected?.education_weight ?? null;
  }, [educationLevel, educationLevels]);

  // När användaren byter val
  const handleEducationChange = (event, newSelection) => {
    if (newSelection !== null) {
      onDataChange("educationLevel", newSelection);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, maxWidth: 600, margin: "0 auto" }}>
      <Typography variant="h6">Steg 4: Välj utbildningsnivå</Typography>

      {educationLevels.length === 0 ? (
        <Typography variant="body1">Inga utbildningsnivåer tillgängliga</Typography>
      ) : (
        <ToggleButtonGroup
          value={educationLevel}
          exclusive // Endast ett val möjligt
          onChange={handleEducationChange}
          orientation="vertical"
          aria-label="Välj utbildningsnivå"
          sx={{ width: "100%" }}
        >
          {educationLevels.map((lvl) => {
            // Kolla om denna nivå är vald
            const isSelected = lvl.id.toString() === educationLevel?.toString();
            // Kolla om denna nivå ingår i det valda valet (har lägre eller samma vikt)
            const isIncluded = selectedWeight !== null && lvl.education_weight <= selectedWeight;

            return (
              <ToggleButton
                key={lvl.id}
                value={lvl.id}
                sx={{
                  justifyContent: "space-between",
                  borderRadius: "5px",
                  fontSize: "16px",
                  padding: "10px",
                  textAlign: "left",
                  backgroundColor: isIncluded ? "#007BFF" : "transparent",
                  color: isIncluded ? "white" : "inherit",
                  "&.Mui-selected": {
                    backgroundColor: "#007BFF",
                    color: "white",
                  },
                }}
              >
                {lvl.name}

                {lvl.description && (
                  <PopoverPrimitive.Root>
                    <PopoverPrimitive.Trigger asChild>
                      <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </PopoverPrimitive.Trigger>
                    <PopoverPrimitive.Portal>
                      <PopoverPrimitive.Content
                        className="p-4 bg-white shadow-md rounded-md text-sm border border-gray-200 max-w-[300px] overflow-auto"
                        side="right"
                        align="center"
                        sideOffset={5}
                        collisionPadding={10}
                        avoidCollisions
                      >
                        <Typography variant="body2">{lvl.description}</Typography>
                      </PopoverPrimitive.Content>
                    </PopoverPrimitive.Portal>
                  </PopoverPrimitive.Root>
                )}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      )}
    </Box>
  );
};

export default StepEducationLevel;
