import React from "react";
import { TextField, Checkbox, FormControlLabel, Box } from "@mui/material";

const StepAge = ({ userInput, onDataChange }) => {
  const { age = "", isCitizen = true } = userInput;

  const handleAgeChange = (event) => {
    const value = event.target.value;
    // Tillåt bara siffror eller en tom sträng (för att kunna rensa fältet)
    if (/^\d*$/.test(value) || value === "") {
      onDataChange("age", value);
    }
  };

  const handleAgeBlur = () => {
    // När fältet tappas ur fokus konverterar vi till ett heltal om det innehåller en siffra
    if (userInput.age !== "") {
      onDataChange("age", parseInt(userInput.age, 10));
    }
  };

  const handleCitizenChange = (event) => {
    onDataChange("isCitizen", event.target.checked);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, maxWidth: 400, margin: "0 auto" }}>
      <TextField
        label="Ange din ålder"
        type="text" // Byter till text för att undvika problem i vissa webbläsare
        inputMode="numeric" // Mobilanpassat tangentbord
        value={age}
        onChange={handleAgeChange}
        onBlur={handleAgeBlur} // Konvertera till ett heltal när användaren lämnar fältet
        helperText="Ange din ålder i hela år."
        fullWidth
      />
      <FormControlLabel
        control={<Checkbox checked={isCitizen} onChange={handleCitizenChange} />}
        label="Jag är svensk medborgare"
      />
    </Box>
  );
};

export default StepAge;
