import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { Link as ScrollLink } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import DarkModeToggle from "./DarkModeToggle.js";
import MenuIcon from "@mui/icons-material/Menu";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isFindScholarshipsPage = location.pathname === "/find-scholarships";

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLogoClick = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/");
    }
  };

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: scrolled ? "white" : "rgba(255, 255, 255, 0.8)",
        transition: "background-color 0.3s ease",
        padding: { xs: "10px 20px", md: "10px 50px" },
        boxShadow: scrolled ? "0 2px 10px rgba(0, 0, 0, 0.1)" : "none",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Klickbar logotyp */}
        <Typography
          onClick={handleLogoClick}
          sx={{
            textDecoration: "none",
            color: "black",
            fontFamily: "Inter, sans-serif",
            fontWeight: 700,
            fontSize: "22px",
            letterSpacing: "-0.5px",
            cursor: "pointer",
          }}
        >
          Stipendio
        </Typography>

        {/* Navigation och Dark Mode Toggle (döljs på /find-scholarships) */}
        {!isFindScholarshipsPage && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 2, sm: 3, md: 4 },
            }}
          >
            <ScrollLink
              to="about-section"
              smooth={true}
              duration={500}
              offset={-70}
              style={{
                textDecoration: "none",
                color: "black",
                fontFamily: "Inter, sans-serif",
                fontWeight: 600,
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              OM OSS
            </ScrollLink>

            {/* Dark Mode Toggle */}
            <DarkModeToggle />

            <Button
              component="a"
              href="/find-scholarships"
              variant="contained"
              sx={{
                backgroundColor: "#007BFF",
                padding: { xs: "6px 12px", sm: "10px 20px", md: "12px 24px" },
                borderRadius: "5px",
                fontSize: { xs: "14px", sm: "16px" },
                fontWeight: "bold",
                fontFamily: "Inter, sans-serif",
                textTransform: "none",
                "&:hover": { backgroundColor: "#0056b3" },
              }}
            >
              HITTA STIPENDIUM
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
