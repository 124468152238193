import React, { useState, useEffect } from "react";
import { Box, Typography, List, ListItem, Button, Card, CardContent, Collapse, Drawer, IconButton, Link, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";

const ResultList = ({ scholarships = [], allScholarships = [], cachedData = {}, userInput = {}, setUserInput, setCurrentStep }) => {
  const [expanded, setExpanded] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showScholarshipList, setShowScholarshipList] = useState(false); // För att visa ofiltrerad lista
  const [userData, setUserData] = useState(userInput);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userInput.age !== "" || userInput.field.length > 0 || userInput.purpose.length > 0) {
      console.log("🔄 Uppdaterar userData i ResultList:", userInput);
      setUserData(userInput);
    }
  }, [userInput]);

  useEffect(() => {
    console.log("📋 Debug: cachedData i ResultList:", cachedData);
    if (cachedData && Object.keys(cachedData).length > 0) {
      setLoading(false);
    } else {
      console.warn("⚠️ cachedData är fortfarande tomt. Väntar...");
      setLoading(true);
    }
  }, [cachedData]);

  const handleToggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const toggleScholarshipList = () => {
    setShowScholarshipList((prev) => !prev); // Toggla ofiltrerad lista
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const resetForm = () => {
    setUserInput({
      age: "",
      isCitizen: true,
      location: { countryIds: [] },
      purpose: [],
      field: [],
      educationLevel: null,
    });
    setCurrentStep(0);
  };

  const mapIdsToNames = (ids, data, fieldName) => {
    if (!ids || ids.length === 0) return ["Ej angivet"];
  
    if (!Array.isArray(data)) {
      console.warn(`⚠️ mapIdsToNames (${fieldName}): Data är inte en array!`, data);
      return ["Laddar..."];
    }
  
    console.log(`🔍 mapIdsToNames (${fieldName}) - IDs:`, ids);
    console.log(`🔍 mapIdsToNames (${fieldName}) - Data:`, data);
  
    return ids
      .map(id => {
        const foundItem = data.find(item => item.id === id);
        return foundItem ? foundItem.name : "Okänt";
      })
      .filter(name => name !== "Okänt");
  };

  return (
    <Box sx={{ textAlign: "center", padding: 4 }}>
      {/* 🔹 Länk för att visa användarens inmatade uppgifter */}
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        <Link component="button" onClick={toggleDrawer(true)} sx={{ cursor: "pointer", textDecoration: "underline", fontWeight: "bold" }}>
          Visa dina inmatade uppgifter
        </Link>
      </Typography>

      <Typography variant="h5" gutterBottom>
        Här är sökresultatet baserat på de uppgifter du angett.
      </Typography>

      {/* 🔹 Filtrerad stipendielista (visas direkt under texten) */}
      {scholarships.length === 0 ? (
        <Typography variant="body1" sx={{ marginBottom: 3 }}>
          Tyvärr finns inga stipendier i vår databas som matchar din sökning.
        </Typography>
      ) : (
        <List sx={{ width: "100%", maxWidth: "600px", margin: "auto", marginBottom: 3 }}>
          {scholarships.map((scholarship, index) => (
            <ListItem key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
              <Button 
                fullWidth 
                variant="outlined" 
                onClick={() => handleToggleExpand(index)}
                sx={{ 
                  textTransform: "none", 
                  justifyContent: "space-between", 
                  textAlign: "left", 
                  padding: "12px", 
                  fontWeight: "bold", 
                  borderRadius: "8px",
                  borderColor: expanded === index ? "#007BFF" : "rgba(0,0,0,0.2)",
                  backgroundColor: expanded === index ? "#E3F2FD" : "white"
                }} 
              >
                {scholarship.title || "Okänt stipendium"}
                {expanded === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>

              <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                <Card sx={{ 
                  width: "100%", 
                  maxWidth: "500px", 
                  marginTop: 1, 
                  backgroundColor: "#f8f9fa",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                  boxShadow: "none"
                }}>
                  <CardContent>
                    <Typography variant="h6">{scholarship.title || "Okänt stipendium"}</Typography>
                    {/* Ytterligare detaljer om stipendiet kan läggas till här */}
                  </CardContent>
                </Card>
              </Collapse>
            </ListItem>
          ))}
        </List>
      )}

      {/* 🔹 Knappar för att visa ofiltrerad lista & återställa formuläret */}
      <Stack direction="row" spacing={2} justifyContent="center" sx={{ marginTop: 2, marginBottom: 2 }}>
        <Button variant="contained" onClick={toggleScholarshipList}>
          {showScholarshipList ? "Dölj stipendielista" : "Visa stipendielista"}
        </Button>
        <Button variant="outlined" onClick={resetForm}>
          Återställ formulär
        </Button>
      </Stack>

      {/* 🔹 Ofiltrerad stipendielista (visas endast när showScholarshipList är true) */}
      <Collapse in={showScholarshipList} timeout="auto" unmountOnExit>
        <Typography variant="h6" sx={{ marginTop: 4, marginBottom: 2 }}>
          Alla tillgängliga stipendier
        </Typography>
        <List sx={{ width: "100%", maxWidth: "600px", margin: "auto" }}>
          {allScholarships.map((scholarship, index) => (
            <ListItem key={index} sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}>
              <Button 
                fullWidth 
                variant="outlined" 
                onClick={() => handleToggleExpand(index)}
                sx={{ 
                  textTransform: "none", 
                  justifyContent: "space-between", 
                  textAlign: "left", 
                  padding: "12px", 
                  fontWeight: "bold", 
                  borderRadius: "8px",
                  borderColor: expanded === index ? "#007BFF" : "rgba(0,0,0,0.2)",
                  backgroundColor: expanded === index ? "#E3F2FD" : "white"
                }} 
              >
                {scholarship.title || "Okänt stipendium"}
                {expanded === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>

              <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                <Card sx={{ 
                  width: "100%", 
                  maxWidth: "500px", 
                  marginTop: 1, 
                  backgroundColor: "#f8f9fa",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                  boxShadow: "none"
                }}>
                  <CardContent>
                    <Typography variant="h6">{scholarship.title || "Okänt stipendium"}</Typography>
                    {/* Ytterligare detaljer om stipendiet kan läggas till här */}
                  </CardContent>
                </Card>
              </Collapse>
            </ListItem>
          ))}
        </List>
      </Collapse>

      {/* 🔹 Slideout-panel med användarens inmatade uppgifter */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 350, padding: 3 }}>
          <IconButton onClick={toggleDrawer(false)} sx={{ position: "absolute", right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            Dina inmatade uppgifter
          </Typography>
          
          <Typography variant="body2">
            <strong>Ålder:</strong> {userData.age || "Ej angivet"}
          </Typography>
          
          <Typography variant="body2">
            <strong>Land:</strong> 
            {loading || !cachedData.countries 
              ? "Laddar..." 
              : mapIdsToNames(userData.location?.countryIds || [], cachedData.countries, "countries").join(", ")}
          </Typography>
          
          <Typography variant="body2">
            <strong>Ämnesområde:</strong> 
            {loading || !cachedData.field 
              ? "Laddar..." 
              : mapIdsToNames(userData.field || [], cachedData.field, "field").join(", ")}
          </Typography>
          
          <Typography variant="body2">
            <strong>Syfte:</strong> 
            {loading || !cachedData.purpose 
              ? "Laddar..." 
              : mapIdsToNames(userData.purpose || [], cachedData.purpose, "purpose").join(", ")}
          </Typography>
          
          <Typography variant="body2">
            <strong>Utbildningsnivå:</strong> 
            {loading || !cachedData.educationLevels 
              ? "Laddar..." 
              : mapIdsToNames(userData.educationLevel ? [userData.educationLevel] : [], cachedData.educationLevels, "educationLevels").join(", ")}
          </Typography>

          <Button 
            variant="contained" 
            fullWidth 
            sx={{ marginTop: 3 }} 
            onClick={() => setCurrentStep(0)}
          >
            Ändra dina uppgifter
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
};

export default ResultList;