import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Hero = () => {
  return (
    <Box
      sx={{
        textAlign: "left",
        padding: { xs: "80px 20px", md: "100px 40px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start", // Korrekt justering istället för "left"
      }}
    >


      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          marginTop: "10px",
          marginBottom: "10px",
          lineHeight: 1.2,
        }}
      >
        Hitta rätt stipendium <br /> med en enkel sökning
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontSize: "18px",
          maxWidth: "600px",
          marginBottom: "20px",
          color: "#666",
        }}
      >
        Stipendio samlar stipendier från hela Sverige så att du snabbt kan hitta finansiering för studier, forskning eller andra projekt <br/>– i Sverige eller utomlands.
      </Typography>

      {/* Fixad knapp med maxbredd */}
      <Button
        component={RouterLink}
        to="/find-scholarships"
        variant="contained"
        id="hero-button"
        sx={{
          backgroundColor: "#007BFF",
          padding: { xs: "8px 16px", sm: "10px 20px", md: "12px 24px" },
          borderRadius: "5px", // Fyrkantig form
          fontSize: { xs: "14px", sm: "16px" },
          fontWeight: "bold",
          fontFamily: "Inter, sans-serif",
          textTransform: "none",
          maxWidth: "250px", // Sätter en maxbredd
          width: "100%", // Säkerställer att knappen är responsiv
          textAlign: "center",
          "&:hover": { backgroundColor: "#0056b3" },
        }}
      >
        HITTA STIPENDIUM
      </Button>
    </Box>
  );
};

export default Hero;
