import React from "react";
import Header from "./Header.js"; // Lägg till .js
import Footer from "./Footer.js"; // Lägg till .js
import { Container } from "@mui/material";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Container sx={{ paddingTop: "2rem", paddingBottom: "4rem" }}>
        {children}
      </Container>
      <Footer />
    </>
  );
};

export default Layout;
